<template>
    <div>
     
        <div class="hero" ref="my-hero">
            <!-- <header class="header contenedor ">
                <div class="logo">
                    <img :src="require('@/assets/images/logo/logo-the-fives.png')" alt="">  
                </div>
                <nav class="navegacion">                
                    <router-link :to="{ name: 'sales' }" class="mr-1">Experiences</router-link>
                    <cart-dropdown/>                                                     
                </nav>
            </header> -->
            
            <div class="contenido-hero contenedor">
                <h1>FIND UNIQUE EXPERIENCES</h1>
                <!-- <form action="#">
                    <input type="text" name="busqueda" class="busqueda" placeholder="Destination, activity">
                    <input type="submit" value="Search">
                </form> -->
            </div>
        </div>
    </div>
</template>

<script>
import CartDropdown from '@/modules/shop/components/cart/CartDropdown'

export default {
    components:{    
        CartDropdown
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landing.scss";
</style>