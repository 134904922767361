<template>
    <div>   
        <Header/>

        <!-- <HeroHome/> -->
        <b-container class="mb-4" v-if="!isloadingProducts"> 
            <BestSellers v-if="bestSellers.length" class="mt-3"/>                        
            <FlagshipProduct />            
            <CategoryProducts/>                    
        </b-container>
        <b-container class="mb-4" v-else> 
            <SkeletonBestSellers />       
            <SkeletonFlagshipProduct />       
            <SkeletonCategoryProducts />       
        </b-container>
        
        <!-- <Footer/>     -->
    </div>
</template>
<script>
import { mapState, mapActions,  mapMutations, mapGetters } from 'vuex'
import Header from '@/modules/shop/components/home/Header'
import HeroHome from '@/modules/shop/components/home/HeroHome'
import BestSellers from '@/modules/shop/components/home/BestSellers'
import FlagshipProduct from '@/modules/shop/components/home/FlagshipProduct'
import CategoryProducts from '@/modules/shop/components/home/CategoryProducts'

import Footer from '@/modules/shop/components/home/Footer'

//skeletons
import SkeletonBestSellers from '@/modules/shop/components/home/skeletons/SkeletonBestSellers'
import SkeletonFlagshipProduct from '@/modules/shop/components/home/skeletons/SkeletonFlagshipProduct'
import SkeletonCategoryProducts from '@/modules/shop/components/home/skeletons/SkeletonCategoryProducts'

import { toJson, overWriteAxiosHeaders } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'

export default {
    mixins: [market],
    components:{
        SkeletonBestSellers,
        SkeletonFlagshipProduct,
        SkeletonCategoryProducts,
        Header,
        HeroHome,
        BestSellers,        
        FlagshipProduct,
        CategoryProducts,
        Footer,        
    },
    data(){
        return {
            isloadingProducts: false
        }
    },
    async created(){
        this.clearStorage()
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
        await this.init()       
    },
    mounted(){
        // this.initCarousel()
    },
    computed:{
        ...mapState('shop',['cart','customerCurrency']),               
        ...mapState('auth',['queryParams','user', 'isLogged']),               
        ...mapState('start',['currencies', 'categories', 'hotels', 'languages', 'categories']),
        ...mapState('products',['bestSellers' ]),             
        ...mapGetters('products',['filteredProducts']), 
    },
    methods:{
        ...mapActions('shop',['getInitialContentMarket']),   
        ...mapActions('products',['getInitialContentLanding','fetchProductsByCategories', 'fetchBestSellerProducts']),    
        ...mapMutations('products',['setBestSellersProducts','setProducts']),       
        ...mapMutations('auth',['setDataQueryParams']), 
        ...mapMutations('shop',['setCurrencyCustomer']),
        ...mapMutations('start',['setCategories','setCurrencies','setHotels','setLanguages','setSelectedCategory']),     
        async init(){
            this.isloadingProducts = true
            this.resetStateHome()
            this.setInitialParams() //ejecutado desde le mixin
            
            if( this.queryParams ){
                overWriteAxiosHeaders( this.queryParams ) //sobreescribo axios
                if( !this.isLogged ){        
                    await this.loginApp()                    
                }
                if( this.isLogged ){
                    await this.appIsLogged() //pedir datos iniciales si la app está conectada (categorias, currencies, hotels, languages)    
                    await this.getBestSellerProducts() //get best seller products             
                    // await this.getInitialProductsByCategory() //pedir productos, de la primera categorái existente
                }
            }

            if( !this.queryParams ){
                this.$router.push({name: 'not-found' })
            }
            this.isloadingProducts = false
        }, 
        async appIsLogged(){
            const initialStatesAreEmpty = this.initialStatesAreEmpty()              
            if( initialStatesAreEmpty ){
                const { categories, currencies, hotels, languages } = await this.getInitialContentMarket() //TODO: pedir datos si no hay categorias, currencies, hotels o languages
                this.setCategories( categories ) 
                this.setCurrencies(currencies)
                this.setHotels(hotels)
                this.setLanguages(languages) 
                this.putCurrencyCustomer() //poner la moneda para el cliente 
            }
            document.title = `${this.user.name} ${this.user.lastname}`  
        },
        initialStatesAreEmpty(){  
            const {  withSubCategories } = this.categories   
            if( !this.currencies.length || !this.hotels.length || !this.languages.length || !withSubCategories.length ){
                return true
            }
            return false
        },
        putCurrencyCustomer() {
            const divisa = toJson( this.currencies.find( currency => currency.code === 'USD') )
            if (!this.customerCurrency) {
                this.setCurrencyCustomer(divisa)
            }
        },
        async getBestSellerProducts(){
            if( !this.bestSellers.length ){
                const products = await this.fetchBestSellerProducts()
                this.setBestSellersProducts(products) 
            }
        },
        async getInitialProductsByCategory(){
            if( !this.filteredProducts.length  ){
                const { defaults } = this.categories
                this.setSelectedCategory( defaults[0] )      
                const products = await this.fetchProductsByCategories(defaults[0]) //TODO: pedir datos si no hay products, defaults[1] es el que está despues de bestsellers
                this.setProducts( products ) 
            }                        
        }            
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/landing.scss";
</style>
